$avatar-size: 40px;

.cc-peers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: $unit;
    li {
        display: flex;
        align-items: center;
        gap: $unit * 0.5;
    }
}

.cc-peer__avatar {
    background-color: var(--color-fade);
    border-radius: $avatar-size;
    font-size: 0;
    height: $avatar-size;
    line-height: 0;
    overflow: hidden;
    position: relative;
    width: $avatar-size;
    &:before,
    &:after {
        background-color: var(--color-fade);
        border-radius: $avatar-size;
        content: "";
        height: $avatar-size * 0.5;
        left: $avatar-size * 0.25;
        position: absolute;
        width: $avatar-size * 0.5;
    }
    &:before {
        top: $avatar-size * 0.25;
    }
    &:after {
        top: $avatar-size * 0.75;
    }
}

.cc-peer__name {
    display: block;
}

.cc-peer__name-group {
    @include font-size(font-size(milli));
}