.cc-masthead__outer {
    @include var(background-color, header-bg);
}

.cc-masthead {
    @include padding($unit);
    @include var(color, header-color);
    align-items: center;
    display: flex;
    gap: $unit;
    max-width: $block * 9;
    margin: 0 auto;
    @include screen-size(m) {
        @include padding(n $unit * 2);
        gap: $unit * 2;
    }
    @include screen-size(l) {
        @include padding($unit * 2 n);
    }
}

.cc-masthead__logo {
    @include var(color, color-white);
    @include font-size(font-size(beta));
    align-items: center;
    gap: 0.5em;
    display: flex;
    font-family: $heading-font-family-alt;
    font-weight: bold;
    @include screen-size(l) {
        min-width: $block * 1.5
    }

    svg {
        height: 1.2em;
        width: auto;
    }
}

.cc-masthead__nav {
    flex: 1;
}

.cc-masthead__user {
    display: flex;
    gap: $unit;

    a {
        font-weight: bold;
    }
}