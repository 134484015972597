$primary-table-row-bg: $white;
$primary-table-row-bg-other: lighten($lightgrey, 2%);
$primary-table-row-bg-hover: lighten($brand-color-2, 42%);

.c-table {
    // Primary list
    &.\+primary-list {
        @extend .\+row-gaps;
        @extend .\+padded-cells;
        thead {
            th {
                @extend .u-font-small;
            }
            .\+numerical {
                input {
                    text-align: right;
                }
            }
            input {
                --field-border: #{$shadow-reset};
                --field-shadow: #{$shadow-reset};
                @include var(background-color, color-white);
                line-height: inherit;
                min-height: 0;
                &::placeholder {
                    opacity: 0.5;
                }
            }
        }
        tbody {
            > tr {
                background-color: $primary-table-row-bg;
                &:nth-child(even) {
                    background-color: $primary-table-row-bg-other;
                }
                &:hover {
                    background-color: $primary-table-row-bg-hover;
                }
                &.\+expanded {
                    background-color: rgba($darkgrey, $shade-amount);
                    > td {
                        @include padding($unit);
                        @include screen-size(l) {
                            @include padding($unit * 2);
                        }
                        table {
                            @include table-borders(
                                lined,
                                $table-border-width,
                                solid,
                                $table-border-color
                            );
                            caption {
                                @include h3;
                                @include margin(n n em($unit * 0.5) n);
                            }
                            tr {
                                background-color: transparent;
                            }
                            thead tr:last-child * {
                                border-bottom: $table-border-width solid $table-border-color;
                            }
                        }
                    }
                }
            }
        }

        &.\+expandable-rows tbody tr:not(.\+open):not(.\+hi):hover {
            box-shadow: $shadow;
            position: relative;
            z-index: zindex(bottom);
        }

        .c-table__row {
            &.\+filters {
                @extend .u-font-small;
                th,
                td {
                    @include var(background-color, color-fade);
                }
            }
            &.\+sticky {
                th,
                td {
                    z-index: 1;
                }
                th {
                    @include var(background-color, color-site);
                }
            }
            &.\+selected {
                background-color: mix($white, $brand-color-2, 95%);
                box-shadow: $shadow;
                z-index: 1;
            }
            &.\+hi {
                box-shadow: $shadow-heavy;
                position: relative;
                z-index: zindex(bottom);
            }
        }
        .c-pill {
            --pill-bg: transparent;
            --pill-text: var(--color-grey);
            --button-border: inset 0 0 0 #{$border-width} currentColor;
        }
    }
}

th.c-table__cell {
    .c-table.\+primary-list &.\+sticky {
        @include var(background-color, color-site);
    }
    .cc-slider &.\+sticky,
    .cc-widget &.\+sticky {
        @include var(background-color, color-white);
    }
}

td.c-table__cell {
    .c-table.\+primary-list tr &.\+sticky {
        background-color: $primary-table-row-bg;
    }
    .c-table.\+primary-list tr:nth-child(even) &.\+sticky {
        background-color: $primary-table-row-bg-other;
    }
    .c-table.\+primary-list tr:hover &.\+sticky {
        background-color: $primary-table-row-bg-hover;
    }
    .cc-slider &.\+sticky,
    .cc-widget &.\+sticky {
        @include var(background-color, color-white);
    }
    &.\+expander {
        cursor: pointer;
        .c-icon {
            @include font-size(font-size(nano));
            opacity: $fade;
            pointer-events: none;
            transform: rotate(-90deg);
            transition: $duration * $smoothness;
        }
        &:hover {
            .c-icon {
                opacity: 1;
            }
        }
        tr:hover & {
            .c-icon {
                @include var(color, color-link);
            }
        }
        tr.\+open & {
            .c-icon {
                transform: rotate(0deg);
            }
        }
    }
}

.c-table__container {
    overflow: auto;
}
