@use "sass:math";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

@import "~morse-css/dist/scss/morse-init.scss";
@import "morse-theme";
@import "~morse-css/dist/scss/morse-post.scss";
@import "./custom-styles/all.scss";

// Responsiveness tweaks

:root {
    --data-media-s: #{em(math.div($fixed-width, 4), round)};
    --data-media-m: #{em(math.div($fixed-width, 2), round)};
    --data-media-l: #{em($fixed-width, round)};
    --data-media-xl: #{em($fixed-width * 1.5, round)};
}

// Selects
.c-select {
    background: #fff no-repeat right 8px center
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0, 0 32, 0 16, 24" style="fill: rgba%28150, 150, 150, 0.8%29"></polygon></svg>');
    background-size: 12px 7px;
}

// NProgress

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: var(--color-3);

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}
