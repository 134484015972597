.c-form {
    fieldset {
        display: block;
        padding: 0;
    }
}

.c-form__block {
    @include var(background-color, color-white);
    @include padding($unit);
    box-shadow: $shadow-light;
    border-radius: $radius;
    max-width: $block*3;
    @include screen-size(m) {
        @include padding($unit*2);
    }
    + .c-form__block {
        @include margin($unit n n n);
        @include screen-size(m) {
            @include margin($unit*2 n n n);
        }
    }
    + .c-form__actions {
        @include margin($unit n n n);
        @include screen-size(m) {
            @include margin($unit*2 n n n);
        }
    }
}