.cl-main {
    @include padding($unit * 0.5);
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header header header"
        "one one one"
        "two two two"
        "three three three";
    max-width: $block * 9;
    margin: 0 auto;
    @include screen-size(m) {
        @include padding($unit);
    }
    @include screen-size(l) {
        grid-template-areas:
            "header header header"
            "one one two"
            "one one three";
    }
    @include screen-size(xl) {
        grid-template-areas:
            "header header header"
            "one two three";
    }
}

.cl-main__header {
    @include padding($unit * 0.5);
    grid-area: header;
    @include screen-size(m) {
        @include padding($unit);
    }
}

.cl-main__primary {
    @include padding($unit * 0.5);
    grid-area: one;
    @include screen-size(m) {
        @include padding($unit);
    }
}

.cl-main__secondary {
    @include padding($unit * 0.5);
    grid-area: two;
    max-width: $block * 2;
    @include screen-size(m) {
        @include padding($unit);
    }
}

.cl-main__third {
    @include padding($unit * 0.5);
    grid-area: three;
    max-width: $block * 2;
    @include screen-size(m) {
        @include padding($unit);
    }
}
