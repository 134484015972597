@use "sass:math";
// Central Settings

$space: 16;
$columns: 6;
$unit: 1px * $space;
$block: $unit * $space;
$box-sizing: border-box;
$class-dark: s-dark;
$fs-ratio: $minor-third;
$mqs: orig m;

// BROWSER SETTINGS

// CSS Variables
$css-vars-use-native: false;

// CSS Logical Properties (Direction)
$css-dir-use-logical: true;
$css-dir-use-logical-only: false;

// iOS version
$iOS: 11.2;
$iOS-lte-5: true;

// IE Support
$ie-9: true;
$ie-lte-8: true;
$ie-lte-9: true;
$ie-gte-10: true;

// SITE SIZE

$is-site-responsive: true;
$is-site-fluid: true;
$fixed-width: $block * $columns;
$fluid-width: 100%;

// Overarching settings

// ASPECT RATIO

$aspect-ratio: (
    landscape: 75%,
    square: 100%,
    portrait: math.div(400%, 3),
    widescreen: 56.25%,
);

// BASE SPACING

$rem-units: true;
$vertical-space: $unit;
$touch-target-ratio: math.div(44px, $unit);
$touch-target: $unit * $touch-target-ratio;

// Blocks
$block-ratio: 1.5;

// Padding
$padding: $unit;
$padding-ratio: 1.5;
$padding-mqs: $mqs;
$margin: $unit;
$margin-ratio: 2;
$margin-ratio: 2;
$margin-mqs: $mqs;

// Radius
$radius: math.div($unit, 3);
$radius-mqs: orig m- m;

// Borders
$border-width: 2px;

// SCREEN SIZE

$screen-size: (
    s: em(math.div($fixed-width, 4), round),
    m: em(math.div($fixed-width, 2), round),
    l: em($fixed-width, round),
    xl: em($fixed-width * 1.5, round),
    sh: em(360px, round),
    mh: em(500px, round),
);

// SITE DIRECTION

$direction: ltr;
$start: left;
$end: right;

// SIZE RATIO

$size-ratio: (
    small: 1,
    medium: 1,
);

// TIMINGS

$smoothness: 0.2;

// Animations
$duration: 1s;
$curve: linear;

// Z-INDEX

$zindex: (
    top: 1000,
    middle: 100,
    bottom: 1,
    base: 0,
    below: -1,
);

// Base Colors

$fade: 0.5;
$black: #000000;
$darkgrey: #2b2e32;
$grey: #a3b3c3;
$lightgrey: #f5f8fa;
$white: #ffffff;
$red: #dd125f;
$orange: #fc3823;
$orange-light: #ff7663;
$gold: #e77d0f;
$yellow: #dfe41e;
$green: #13ba42;
$cyan: #00c3c2;
$blue: #369dd8;
$navy: #002c5a;
$purple: #6b1ee4;
$magenta: #e41ecc;
$brown: #ab9a64;

$error: $red;
$warning: $gold;
$success: $green;

$brand-color-1: $darkgrey;
$brand-color-2: $orange;
$brand-color-2-light: $orange;
$brand-color-3: $blue;
$brand-color-4: $purple;

$foreground-color: darken($darkgrey, 10%);
$background-color: $lightgrey;

$border-color: rgba($grey, 0.5);

$white-text: color-white;

$grad-0-dark: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
$grad-0-dark-x2: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
$grad-0-dark-ltr: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
$grad-light-0: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
$grad-light-0-x2: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
$grad-light-0-ltr: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));

$grad-blue: linear-gradient($brand-color-2-light, $brand-color-2);

$shade-amount: 0.06;
$tint-amount: 0.6;

$shade: rgba($black, $shade-amount);
$tint: rgba($white, $tint-amount);

// Link Light Color
$link-light-color: var(--color-link-alt);

// Dark Mode
$enable-dark-mode: false;
$add-dark-bg-link-elements: false;

// Fill Utility Settings
$smart-background-fill: true;

// Typography

$base-line-height: $unit * 1.5;
$base-font-size: $unit;
$font-features: liga kern;
$line-height-ratio: math.div($base-line-height, $base-font-size);

// Font Weight and Style

$bold: bold;
$bold-font-family: inherit;

$italic: italic;
$italic-font-family: inherit;

$bold-italic-font-family: inherit;

$lighter: lighter;

// Hyphenate Browser Prefixes
$hyphenate: false;
$hyphenate-limit-chars: 6 3 3;
$hyphenate-limit-lines: 2;
$hyphenate-limit-last: always;
$hyphenate-limit-zone: 8%;
$hyphen-prefixes: webkit ms spec;

// System Fonts
$system-fonts-serif: Georgia, Times, "Times New Roman", serif;
$system-fonts-sans: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$system-font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;

// Default Fonts
$icon-font: "icomoon", sans-serif;
$base-font-family: $system-fonts-sans;

// Mono Fonts
$mono-font-family: $system-font-mono;
$mono-font-size: 0.95em;

// Links
$link-weight: normal;
$link-visited-opacity: 0.8;

// Weights / Styles
$bold: bold;
$italic: italic;

// Lists
$dt-weight: $bold;

// Quotes
$english-quotes: true;
$open-quote: null;
$close-quote: null;

// Headings
$heading-font-family: inherit;
$heading-font-family-alt: "Avenir Next", Avenir, $system-fonts-sans;
$heading-weight: $bold;
$heading-color: inherit;

$letter-spacing-unit: 0.1em;

$h1-size: delta;
$h1-size-m: false;
$h1-size-l: zeta;
$h1-font-family: $heading-font-family-alt;
$h1-color: $heading-color;
$h1-weight: $heading-weight;
$h1-opacity: 1;
$h1-lh: false;
$h1-lh-m: false;
$h1-lh-l: false;

$h2-size: gamma;
$h2-size-m: false;
$h2-size-l: delta;
$h2-font-family: $heading-font-family-alt;
$h2-color: $heading-color;
$h2-weight: $heading-weight;
$h2-opacity: 1;
$h2-lh: false;
$h2-lh-m: false;
$h2-lh-l: false;

$h3-size: beta;
$h3-size-m: false;
$h3-size-l: false;
$h3-font-family: $heading-font-family;
$h3-color: $heading-color;
$h3-weight: $heading-weight;
$h3-opacity: 1;
$h3-lh: false;
$h3-lh-m: false;
$h3-lh-l: false;

$h4-size: alpha;
$h4-size-m: false;
$h4-size-l: false;
$h4-font-family: $heading-font-family;
$h4-color: $heading-color;
$h4-weight: $heading-weight;
$h4-opacity: 1;
$h4-lh: false;
$h4-lh-m: false;
$h4-lh-l: false;

$h5-size: false;
$h5-size-m: false;
$h5-size-l: false;
$h5-font-family: $heading-font-family;
$h5-color: $heading-color;
$h5-weight: $heading-weight;
$h5-opacity: 1;
$h5-lh: false;
$h5-lh-m: false;
$h5-lh-l: false;

$h6-size: false;
$h6-size-m: false;
$h6-size-l: false;
$h6-font-family: $heading-font-family;
$h6-color: $heading-color;
$h6-weight: $heading-weight;
$h6-opacity: 1;
$h6-lh: false;
$h6-lh-m: false;
$h6-lh-l: false;

$hlabel-size: false;
$hlabel-size-m: false;
$hlabel-size-l: false;
$hlabel-font-family: $heading-font-family;
$hlabel-color: $heading-color;
$hlabel-weight: $heading-weight;
$hlabel-opacity: 1;
$hlabel-lh: false;
$hlabel-lh-m: false;
$hlabel-lh-l: false;

// Site Template

// Overall GridLayout Box and App Box settings
$grid-mqs: orig m l; // orig s m l xl

// GridLayout Box settings

$grid-columns: 6;
// Set additional column amounts
$grid-col-x: 1 2 3 4;
$grid-col-x-fr: false;

$grid-column-gap: 1rem;
// Set additional column gaps
$grid-gap-x: 0 1 2;

// App Box settings

$app-column-gap: $grid-column-gap;
// Set additional column gaps
$app-gap-x: $grid-gap-x;
// Set media queries
$app-mqs: $grid-mqs;
$app-gap-mqs: $app-mqs;

// App WrapLayout
$wrap-width: $fixed-width;

// Site layout settings
$min-body-height: $unit * 36;

// Columns
$column-count-x: 1 2 3 4;
$column-gap: em($unit);
$column-gap-x: 0 2;
$column-mqs: orig m l;

// Flex / Grid
$flex-gap: $grid-column-gap;
$grid-gap: $grid-column-gap;

// Decoration

// BORDERS
$border-width: 2px;
$border-mqs: $mqs;

// SHADOWS
$shadow-depth-reset: 0 0 0;
$shadow-depth-tight: 0 1px 2px;
$shadow-depth-light: 0 1px 4px;
$shadow-depth-medium: 0 2px 10px;
$shadow-depth-heavy: 0 3px 16px;
$shadow-depth-ambient: 0 0 24px;

$shadow-color-0: rgba(0, 0, 0, 0);
$shadow-color-1: rgba($darkgrey, 0.1);
$shadow-color-2: rgba($darkgrey, 0.2);
$shadow-color-3: rgba($darkgrey, 0.3);
$shadow-color-4: rgba($darkgrey, 0.4);
$shadow-color-5: rgba($darkgrey, 0.5);

$shadow-reset: $shadow-depth-reset $shadow-color-0;
$shadow-light: $shadow-depth-light $shadow-color-1;
$shadow: $shadow-depth-light $shadow-color-2;
$shadow-medium: $shadow-depth-medium $shadow-color-2;
$shadow-heavy: $shadow-depth-heavy $shadow-color-3;
$shadow-ambient: $shadow-depth-ambient $shadow-color-1;
$shadow-tight: $shadow-depth-tight $shadow-color-5;

$shadow-light-inset: inset $shadow-light;
$shadow-medium-inset: inset $shadow-medium;

$shadow-cover: inset 0 0 0 20rem;

$shadow-lighten: $shadow-cover rgba($white, 0.25);
$shadow-lighten-x2: $shadow-cover rgba($white, 0.5);
$shadow-darken-half: $shadow-cover rgba($black, 0.05);
$shadow-darken: $shadow-cover rgba($black, 0.1);
$shadow-darken-x2: $shadow-cover rgba($black, 0.2);

$shadow-border: inset 0 0 0 $border-width $border-color;

$emboss-tint: rgba($white, $tint-amount);
$emboss-shade: rgba($black, $shade-amount * 1.5);
$emboss-offset: $unit * 0.25;
$emboss-size: $unit * 0.5;

// UI Items

// Article Main Properties
$article-main-spacing: $unit;
$article-main-spacing-unit: rem;

// Article Heading
$article-heading-spacing: $unit * 0.5;
$article-heading-spacing-md: $unit;
$article-heading-spacing-unit: rem;

$article-h1-top-spacing: 0;
$article-h1-top-spacing-md: $article-h1-top-spacing;
$article-h2-top-spacing: $unit * 0.5;
$article-h2-top-spacing-md: $unit;
$article-h3-top-spacing: $unit * 0.5;
$article-h3-top-spacing-md: $unit;
$article-h4-top-spacing: $unit * 0.5;
$article-h4-top-spacing-md: $article-h4-top-spacing;
$article-h5-top-spacing: $unit * 0.5;
$article-h5-top-spacing-md: $article-h5-top-spacing;
$article-h6-top-spacing: $unit * 0.5;
$article-h6-top-spacing-md: $article-h6-top-spacing;

// Alert
$alert-bg-color: rgba($black, $shade-amount);
$alert-padding: em(math.div($padding, 2)) em($padding);
$alert-border-width: 0;
$alert-border: solid $border-color;

// Breadcrumb
$breadcrumb-separator: "›";

// BUTTONS
// Button Height
$button-height: em($touch-target);
$button-height-touch: em($touch-target);
$large-button-height: em($touch-target * 1.5); // value

// Button Padding
$button-padding: em($unit * 0.75);
$button-padding-touch: em($unit);

// Resize button ratios
$button-small-ratio: 0.85;
$button-small-padding-ratio: $button-small-ratio;
$button-small-height-ratio: $button-small-ratio;
$button-small-font-ratio: $button-small-ratio;

$button-large-ratio: 1.45;
$button-large-padding-ratio: $button-large-ratio;
$button-large-height-ratio: $button-large-ratio;
$button-large-font-ratio: $button-large-ratio;

// Button Border
$button-border-width: em($border-width);
$button-border-color: rgba($black, 0);
$button-border: inset 0 0 0 $button-border-width $button-border-color;

// Button Radius
$button-radius: em($radius); // 0 | value

// Button Color
$button-gradient: none; // false | value
$button-gradient-hover: none; // false | value

$button-1-gradient: $grad-blue; // false | value
$button-1-gradient-hover: $grad-0-dark; // false | value

// Button typography
$button-font-family: inherit; // inherit | value
$button-font-weight: bold; // normal | bolder | bold | lighter
$button-font-size: alpha; // [number]    Set to '1' to reset
$button-letter-spacing: 0; // [value]     Set to '0' to turn off
$button-text-transform: none; // none | uppercase | lowercase | captilize
$button-font-settings: default;

// Button Weight
$button-weight: normal; // false | bold | normal

$button-1-weight: bold; // false | bold | normal

// Button Shadows
$button-shadow: $shadow-reset; // false | value
$button-shadow-hover: $button-shadow; // false | value
$button-shadow-active: inset $shadow; // false | value
$button-adjust-hover: $shadow-lighten;
$button-adjust-active: $shadow-darken;

$button-1-shadow: $shadow-reset; // false | value
$button-1-shadow-hover: $button-shadow-hover; // false | value
$button-1-shadow-active: $button-shadow-active; // false | value
$button-1-adjust-hover: $button-adjust-hover;
$button-1-adjust-active: $button-adjust-active;

$button-text-adjust-hover: $shadow-darken;
$button-text-adjust-active: $shadow-darken-x2;

$button-text-1-adjust-hover: $shadow-cover rgba($brand-color-2, 0.1);
$button-text-1-adjust-active: $shadow-cover rgba($brand-color-2, 0.2);
$button-text-2-adjust-hover: $shadow-cover rgba($brand-color-3, 0.1);
$button-text-2-adjust-active: $shadow-cover rgba($brand-color-3, 0.2);
$button-text-neg-adjust-hover: $shadow-cover rgba($error, 0.1);
$button-text-neg-adjust-active: $shadow-cover rgba($error, 0.2);
$button-text-pos-adjust-hover: $shadow-cover rgba($success, 0.1);
$button-text-pos-adjust-active: $shadow-cover rgba($success, 0.2);
$button-text-warn-adjust-hover: $shadow-cover rgba($warning, 0.1);
$button-text-warn-adjust-active: $shadow-cover rgba($warning, 0.2);

// Hollow button
$button-hollow-border-width: 1px; // 0 | value

// Button Arrow
$button-arrow-size: em($unit * 0.75);

// Buttons
$buttons-gap: 1px;
$buttons-gap-adds: 0 2;

// BUTTON BAR
$button-bar-mqs: $mqs;

// CHECKLIST

$checklist-item-spacing: em($unit * 0.5);

$checklist-box-size: em($unit * 1.5);
$checklist-box-spacing: em($unit * 2.5);
$checklist-box-border-width: em(2px);

$checklist-tick-width: $checklist-box-size * 0.25;
$checklist-tick-height: $checklist-box-size * 0.45;
$checklist-tick-border-width: $checklist-box-border-width;
$checklist-tick-radius: $radius;
$checklist-tick-transform: rotate(45deg) translate(120%, 10%);

$checklist-radio-size: $checklist-box-size * 0.5;
$checklist-radio-radius: $checklist-box-size;

$checklist-base-color: currentColor;
$checklist-hi-color: var(--color-link);

$checklist-radio-radius: $checklist-box-size;

// TICKBOXES

$checklist-tick-static-marker-color: $checklist-hi-color;
$checklist-tick-static-border-marker-color: $checklist-hi-color;
$checklist-tick-static-bg-color: transparent;
$checklist-tick-static-opacity: 1;
$checklist-tick-static-hover: false;

$checklist-tick-unchecked-marker-color: transparent;
$checklist-tick-unchecked-border-marker-color: $checklist-base-color;
$checklist-tick-unchecked-bg-color: transparent;
$checklist-tick-unchecked-opacity: $fade * 0.5;
$checklist-tick-unchecked-hover: true;

$checklist-tick-checked-marker-color: var(--color-site);
$checklist-tick-checked-border-marker-color: $checklist-hi-color;
$checklist-tick-checked-bg-color: $checklist-hi-color;
$checklist-tick-checked-opacity: 1;
$checklist-tick-checked-hover: false;

// RADIO BUTTONS

$checklist-radio-static-marker-color: $checklist-hi-color;
$checklist-radio-static-border-marker-color: $checklist-hi-color;
$checklist-radio-static-bg-color: transparent;
$checklist-radio-static-opacity: 1;
$checklist-radio-static-hover: false;

$checklist-radio-unchecked-marker-color: transparent;
$checklist-radio-unchecked-border-marker-color: $checklist-base-color;
$checklist-radio-unchecked-bg-color: transparent;
$checklist-radio-unchecked-opacity: $fade * 0.5;
$checklist-radio-unchecked-hover: true;

$checklist-radio-checked-marker-color: var(--color-site);
$checklist-radio-checked-border-marker-color: $checklist-hi-color;
$checklist-radio-checked-bg-color: $checklist-hi-color;
$checklist-radio-checked-opacity: 1;
$checklist-radio-checked-hover: false;

// DIALOG

// Dialog box
$dialog-background: dialog-bg;
$dialog-color: dialog-text;
$dialog-padding-default: 0;
$dialog-radius-default: $radius * 2;
$dialog-shadow-default: $shadow-heavy;
$dialog-border-default: 0;
$dialog-width-default: $unit * 30;

// Inner Parts of the dialog box
$dialog-inner-gaps: 0;
$dialog-inner-padding: var(--dialog-padding);
$dialog-head-padding: $dialog-inner-padding;
$dialog-body-padding: calc(#{$dialog-inner-padding}* 0.5) $dialog-inner-padding;
$dialog-foot-padding: $dialog-inner-padding;

// Dialog Head
$dialog-head-bg: transparent;
$dialog-head-spacing: $unit;

// Dialog Foot
$dialog-foot-bg: var(--color-site);

// Icon size
$dialog-icon-size: beta;
$dialog-icon-size-md: gamma;

// Media Queries
$dialog-mqs: orig m- m;

// DROPDOWN
$dropdown-body-height: em($unit * 20);
$dropdown-body-border: $border-width * 0.5 solid var(--field-border-color);

$dropdown-section-padding-x: em($unit);
$dropdown-section-padding-y: em($unit * 0.25);

$dropdown-item-padding-x: $dropdown-section-padding-x;
$dropdown-item-padding-y: em($unit * 0.25);
$dropdown-item-selected-shadow: $shadow-darken-half;
$dropdown-item-selected-icon-font: default;
$dropdown-item-selected-icon-weight: bold;
$dropdown-item-selected-icon: "✓";
$dropdown-item-disabled-opacity: $fade;

$dropdown-icon-width: em($unit * 2);

$dropdown-group-size: milli;
$dropdown-group-weight: bold;
$dropdown-group-opacity: 1;
$dropdown-group-case: uppercase;
$dropdown-group-border: $dropdown-body-border;

// LABELS

$label-weight: bold; // normal | value
// Inside fieldset
$label-weight-in-fieldset: normal;

// Required note
$label-required-text: "*";
$label-required-gap: em($unit * 0.5);

// Extra Label
$label-note-weight: normal; // inherit | value
$label-note-opacity: $fade; // 0 - 1
$label-note-gap: $unit * 0.5;

// Checkbox
$label-check-weight: normal; // inherit | value
$inline-label-width: $block * 0.5;

// FIELDS

// Field Height
$input-height: em($touch-target);
$input-height-touch: em($touch-target);

// Field Padding
$field-padding-x: em($unit * 0.5);
$field-padding-y: 0;
$field-padding: $field-padding-y $field-padding-x;
$field-padding-x-touch: $field-padding-x * 2;
$field-padding-y-touch: $field-padding-y;
$field-padding-touch: $field-padding-x-touch $field-padding-y-touch;

$textarea-padding-x: $field-padding-x;
$textarea-padding-y: $textarea-padding-x;
$textarea-padding-x-touch: $field-padding-x-touch;
$textarea-padding-y-touch: $textarea-padding-y;

// Field Border
$field-border-width: 2px;
$field-border-color: $border-color;
$field-border: inset 0 0 0 $field-border-width var(--field-border-color);
$field-border-error: inset 0 0 0 $field-border-width var(--field-border-color-error);
$field-border-warn: inset 0 0 0 $field-border-width var(--field-border-color-warn);
$field-border-success: inset 0 0 0 $field-border-width var(--field-border-color-success);

// Field Radius
$field-radius: 0;

// Field Color
$field-font-family: inherit;

// Field Focus
$field-focus-outline: 2px solid rgba($blue, 0.25);

// Select
$select-triangle-width: em($unit * 0.6);
$select-triangle-height: em($unit * 0.4);
$select-triangle-box: em(($unit * 0.25) + ($unit * 1.5));
$select-padding-y: em($unit * 0.25);
$select-pill-gap: em($unit * 0.25);

// Help Text
$field-help-font-size: micro;

// Feedback Text
$field-feedback-font-size: $field-help-font-size;

// Field MQs
$field-mqs: orig m- m;

// INPUTS
$input-mqs: $field-mqs;

// FORM LAYOUTS
$block-form-gap: $unit;
$inline-form-gap: $unit;
$form-mqs: $field-mqs;

// IMAGES
$avatar-border: none;
$avatar-size: $unit * 3;
$avatar-radius: 1000px;
$avatar-shadow: $shadow;

// LOADER
$loader-width: 1em;
$loader-color: currentColor;
$loader-stroke-width: 0.1em;
$loader-speed: $duration;

// MESSAGE

$message-bg-color: transparent;
$message-border: $border-width solid rgba($darkgrey, $shade-amount * 2);
$message-radius: $radius;
$message-height: $block;
$message-padding: em($unit * 0.75) em($unit * 1.25);

$message-line-1-weight: bold;
$message-line-1-size: alpha;
$message-line-1-size-md: $message-line-1-size;
$message-line-1-opacity: 1;
$message-line-2-size: milli;
$message-line-2-size-md: $message-line-2-size;
$message-line-2-opacity: $fade;

$message-icon-size: zeta;
$message-icon-size-md: eta;
$message-icon-opacity: $fade * 0.5;

// METER
$meter-height: 1em;
$meter-radius: 0;
$meter-border: $shadow-border;
$meter-gradient: $grad-light-0, $grad-light-0-ltr;
$meter-value-color: currentColor;

// NAV
$nav-spacing: $unit;
$nav-padding: $nav-spacing * 0.5;
$nav-link-color: var(--color-link);
$nav-current-color: var(--color-white);
$nav-current-weight: bold;
$nav-mqs: orig s m- m l;
$nav-mqs-hover: orig m- m course;

// PAGER
// Pager Item
$pager-bg: none;
$pager-color: var(--color-link);
$pager-padding: n $padding * 0.5;
$pager-padding-mw: n $padding * 0.75;
$pager-weight: normal;
$pager-height: $touch-target;
$pager-height-md: $unit * 2;
$pager-min-width: $touch-target;
$pager-min-width-mw: 0;
//$pager-border                 : inset 0 0 0 $border-width var(--border-color);
$pager-border: none;

// Selected Item
$pager-selected-bg: $pager-bg;
$pager-selected-color: var(--color-text);
$pager-selected-border: inset 0 0 0 $border-width currentColor;
$pager-selected-weight: $pager-weight;

// Hover state on Item (not selected)
$pager-hover-bg: none;
$pager-hover-color: var(--color-link);
$pager-hover-border: inset 0 0 0 $border-width currentColor;

// Active state on Item (not selected)
$pager-active-bg: none;
$pager-active-color: var(--color-1);
$pager-active-border: inset 0 0 0 $border-width currentColor;

// PILLS
$pill-height: $unit * 1.5;
$pill-line-height: $unit * 1.5;
$pill-radius: $radius;
$pill-hover: false;

// Pill Padding
$pill-padding: em($unit * 1);
$pill-padding-touch: em($unit * 1);

// Pill Color
$pill-gradient: $button-gradient;
$pill-gradient-hover: $button-gradient-hover;

$pill-1-gradient: $button-1-gradient;
$pill-1-gradient-hover: $button-1-gradient-hover;

// Pill typography
$pill-font-family: $button-font-family;
$pill-font-weight: $button-font-weight;
$pill-letter-spacing: $button-letter-spacing;
$pill-text-transform: $button-text-transform;
$pill-font-settings: $button-font-settings;
$pill-font-size: milli;
$pill-font-weight: $button-font-weight;

// Pill Border
$pill-border-width: em($border-width);
$pill-border-color: rgba($black, 0);
$pill-border: inset 0 0 0 $pill-border-width $pill-border-color;

// Pill Shadows
$button-shadow: $shadow-reset;
$button-shadow-hover: $button-shadow;
$button-shadow-active: inset $shadow;
$button-adjust-hover: $shadow-lighten;
$button-adjust-active: $shadow-darken;

$button-1-shadow: $shadow;
$button-1-shadow-hover: $button-shadow-hover;
$button-1-shadow-active: $button-shadow-active;
$button-1-adjust-hover: $button-adjust-hover;
$button-1-adjust-active: $button-adjust-active;

// Between Pills
$pill-gap: em($unit * 0.5);

// POP IN / POP OUT
$pop-in-inset: $unit;
$pop-in-ratio: 2;
$pop-up-mqs: $mqs;

// PROGRESS
$progress-height: 1em;
$progress-radius: $progress-height;
$progress-border: none;
$progress-gradient: $grad-light-0, $grad-light-0-ltr;
$progress-value-animation: slide-in-from-left 1s ease-in-out; // Firefox only
$progress-value-color: currentColor;
$progress-value-incomplete: $warning;
$progress-value-complete: $success;

// RANGE
$range-thumb-width: 1em;
$range-thumb-height: $range-thumb-width;
$range-thumb-radius: $range-thumb-width;

$range-track-bg: var(--color-fade);
$range-track-height: $range-thumb-height * 0.25;
$range-track-radius: $range-track-height;
$range-thumb-shadow: $shadow-tight;

// REVEAL CONTENT
$reveal-content-mqs: orig m- m;

// SWITCH
$switch-size: $unit * 1.5;
$switch-padding: $border-width;
$switch-background: var(--color-shade-x2);
$switch-color-off: var(--color-dark);
$switch-color-on: var(--color-link);
$switch-speed: $duration * $smoothness;

// TABLES

$table-padding: em($unit);
$th-font-weight: bold;

// Table Cells
$table-cell-padding-x: $unit * 0.5;
$table-cell-padding-y: $unit * 0.25;

// Table Rows
$table-head-bg: transparent;
$table-body-bg: transparent;
$table-row-odd: rgba($black, $shade-amount);
$table-row-even: rgba($white, $tint-amount);

// Table Row Hover States
$table-row-hover-color: var(--color-fade);
$table-row-active-color: var(--color-fade-x2);
$table-row-select-color: var(--color-highlight);

// Table Borders
$table-border-width: $border-width;
$table-border-color: var(--color-fade-x2);

$table-head-border-width: $table-border-width;
$table-head-border-color: currentColor;

// Table Frame
$table-frame-border-width: $table-border-width;
$table-frame-border-color: $table-border-color;

// TABS
$tab-gap: 0;
$tab-padding: $unit * 0.5 $unit;
$tab-current-fill: var(--tab-current);
$tab-content-padding: $tab-padding;
$tab-content-fill: $tab-current-fill;
$tab-mqs: $mqs;

// TOOLTIP
$arrow-size: $unit * 0.5;
$tooltip-padding: $unit * 0.25 $unit * 0.5;
$tooltip-radius: $button-radius;
$tooltip-mqs: orig m hover;
$tooltip-dir-mqs: $mqs;

// UTILITIES
$display-mqs: orig m- m print;
$flex-mqs: $mqs;
$font-size-mqs: $mqs;
$fraction-mqs: orig s m l;
$pull-mqs: orig;
$pull-dir: true;
$size-mqs: orig m l xl;
$size-dir: false;

// SCOPED STYLES

// CMS CONTENT

$cms-table-padding: 0;
$cms-table-frame: false;
$cms-table-frame-border-width: $table-head-border-width;
$cms-table-frame-border-color: $table-frame-border-color;

$cms-table-cell-padding-y: $table-cell-padding-y;
$cms-table-cell-padding-x: $table-cell-padding-x;

$cms-table-grid: false;
$cms-table-border-type: lined;
$cms-table-pattern-type: none;
$cms-table-header-border: true;
$cms-table-padded-cells: false;

// CSS VARIABLES

$var-theme: (
    unit: $unit,
    // Colors
    color-shade: rgba($black, $shade-amount),
    color-shade-x2: rgba($black, $shade-amount * 2),
    color-tint: rgba($white, $tint-amount),
    color-tint-x2: rgba($white, $tint-amount * 2),
    color-white: $white,
    color-black: $black,
    color-darkgrey: $darkgrey,
    color-grey: $grey,
    color-lightgrey: $lightgrey,
    color-light: $background-color,
    color-dark: $foreground-color,
    color-pos: $success,
    color-neg: $error,
    color-warn: $warning,
    color-red: $red,
    color-orange: $orange,
    color-yellow: $yellow,
    color-green: $green,
    color-cyan: $cyan,
    color-blue: $blue,
    color-purple: $purple,
    color-magenta: $magenta,
    color-1: $brand-color-1,
    color-2: $brand-color-2,
    color-3: $brand-color-3,
    color-4: $brand-color-4,
    color-text-alt: lighten($foreground-color, 20%),
    color-link-alt: lighten($brand-color-2, 20%),
    color-link-default: $brand-color-2,
    color-highlight: rgba($brand-color-2, $shade-amount * 4),
    // Borders
    border-color: $border-color,
    // Buttons
    button-bg: var(--color-1),
    button-text: $background-color,
    button-1: var(--color-2),
    button-2: var(--color-3),
    // Dialog
    dialog-padding: $unit,
    dialog-text: var(--color-text),
    // Fields
    field-bg: $field-bg,
    field-border: $field-border,
    field-border-color: $field-border-color,
    field-color: $field-color,
    field-shadow: $field-shadow,
    field-selected: $shadow-darken-half,
    field-border-color-error: var(--color-neg),
    field-border-color-warn: var(--color-warn),
    field-border-color-success: var(--color-pos),
    field-label-error: inherit,
    field-label-warn: inherit,
    field-label-success: inherit,
    field-focus: $field-focus-outline,
    // Modal
    backdrop-color: rgba($black, $fade * 1.5),
    modal-padding: $unit,
    // Pills
    pill-bg: $foreground-color,
    pill-text: $background-color,
    // Site Header
    header-bg: $brand-color-1,
    header-color: $white,
    // Tables
    cell-sortable: $brand-color-2,
    // Tabs
    tab-current: $white,
    // Tooltips
    tooltip-bg: $foreground-color,
    tooltip-color: $background-color,
);

$var-theme-sm: ();
$var-theme-md: (
    modal-padding: $unit * 2,
);
$var-theme-lg: (
    dialog-padding: $unit * 1.5,
    modal-padding: $unit * 4,
);

$var-theme-light: (
    color-fade: rgba($darkgrey, $shade-amount),
    color-fade-x2: rgba($darkgrey, $shade-amount * 2),
    color-site: $background-color,
    color-text: $brand-color-1,
    color-link: $brand-color-2,
    dialog-bg: $white,
);

$var-theme: map_merge($var-theme-light, $var-theme);

$var-theme-dark: (
    color-fade: rgba($white, $shade-amount * 2),
    color-fade-x2: rgba($white, $shade-amount * 4),
    color-site: $foreground-color,
    color-text: $white,
    color-link: var(--color-link-alt),
    dialog-bg: $foreground-color,
);
