.c-nav {
    &.\+primary {
        a {
            color: var(--color-white);
            font-weight: bold;
            &:hover {
                color: $orange-light;
            }
        }
    }
}
