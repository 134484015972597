.cc-appraisal__heading {
    @include margin(n n);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .cc-panel & .c-heading {
        margin: 0;
    }
}

.cc-appraisal__expectations {
    summary {
        display: list-item;
    }
    ul {
        @include padding(n n $unit n);
        li + li {
            @include margin($unit * 0.5 n n n);
        }
    }
}

.cc-appraisal__text-area {
    @include var(background-color, color-white);
    width: 100%;
}

.cc-appraisal__feedback {
    ul,
    ol {
        @include padding(n n n 1.5em);
    }
    ul {
        list-style: disc outside;
    }
    ol {
        list-style: decimal outside;
    }
}
