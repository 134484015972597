.c-dialog {
    max-height: calc(100% - var(--#{$modal-container-padding}) * 2);
}

.c-dialog__subtitle {
    flex-basis: 100%;
}

.c-dialog__close {
    @extend .c-button;
    @extend .c-button, .\+quiet;
}

.c-dialog__close-icon {
    @extend .c-button__icon;
}

.c-dialog__close-text {
    @extend .c-button__text;
}
