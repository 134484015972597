.cc-page-header {
    @include margin(n n $unit n);
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "ph-back ph-back"
        "ph-title ph-button";
}

.cc-page-header__title {
    grid-area: ph-title;
}

.cc-page-header__back {
    grid-area: ph-back;
}

.cc-page-header__actions {
    grid-area: ph-button;
}